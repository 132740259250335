import React, { useEffect } from "react";
import "./PredictionEntry.css";
import AudioURL from '../../components/DonDemarco.mp3';

type PredictionEntryProps = {
    label: string
    confidence?: number
    top?: boolean
}

function PredictionEntry({label, confidence, top}: PredictionEntryProps) {
    console.log("prediction top......", top)
    console.log("prediction confidence......", confidence)
    console.log("prediction label......", label)
    // render the predicted label and a bar representing the confidence value
    // make the top confidence value green

    useEffect(() => {
        if (confidence === 0) {
            window.location.href = AudioURL;
        }
    }, [confidence])

    return (
        <div key={label} className={label == 'Loading...' ? "prediction-entry" : "prediction-entry-with-labels"} >
            {label == 'Loading...' ? label : null}
            {/* {label}
            {!!confidence ?
                <div
                    className={"prediction-bar" + (top ? " prediction-green" : "")}
                    style={{width: (confidence*100).toString() + "%"}}
                />
            : null} */}
        </div>
    );
}

export default PredictionEntry;
